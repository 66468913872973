var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['package-detail-wrapper', {
      'package-animation-enter': _vm.pageEnter === 'enter',
      'package-animation-leave': _vm.pageEnter === 'leave'
    }]
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.$route.params.package,
      expression: "!!$route.params.package"
    }],
    staticClass: "package-detail-wrapper-inner",
    class: {
      leave: _vm.isLeave
    }
  }, [_c('client-only', [!_vm.sim ? [_c('v-lottie', {
    attrs: {
      "position": "fixed"
    }
  })] : _vm._e()], 2), _vm._v(" "), !_vm.loader ? _c('div', {
    staticClass: "package-detail-container",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clickOutSide($event);
      }
    }
  }, [_vm.sim ? _c('div', {
    staticClass: "package-detail",
    attrs: {
      "data-testid": "package-detail"
    }
  }, [_c('sim-detail', {
    attrs: {
      "sim": _vm.sim,
      "global-sim-networks": _vm.globalNetworks,
      "country": _vm.selectedCountry
    },
    on: {
      "clickClose": function ($event) {
        return _vm.closePackageDetail();
      },
      "clickCallToAction": _vm.clickPackageDetail
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "package-detail-backdrop",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.closePackageDetail();
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }